import React from 'react'
import Proptypes from 'prop-types'

export default function Slide(props) {
    const {className, children} = props
    return (
        <div className={`Slide ${className}`}>
            {children}
        </div>
    )
}

Slide.defaultProps = {
    className: ""
}

Slide.propTypes = {
    className: Proptypes.string,
    children:Proptypes.node
}