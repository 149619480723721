import React from 'react'
import Proptypes from  'prop-types'
import { Link } from 'gatsby'

export default function GalleryItem(props) {
    const {image, text, width, height, mr, link } = props
    return (
        <Link to={link} className="GalleryItem-link">
            <div className="GalleryItem" style={{backgroundImage: `url(${image})`, maxWidth:width, height:height, marginRight:mr}}>
                <span className="GalleryItem-text">{text}</span>
            </div>
        </Link>
    )
}

GalleryItem.defaultProps = {
    image:"",
    text:"",
    width:"",
    height:"",
    mr:"",
    link:"/",
}

GalleryItem.propTypes = {
    image: Proptypes.string,
    text: Proptypes.string,
    width: Proptypes.string,
    height: Proptypes.string,
    mr: Proptypes.string,
    link: Proptypes.string,
}