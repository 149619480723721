import React from 'react'
import Proptypes from 'prop-types'
import { Link } from 'gatsby'

const ProductCard = React.forwardRef((props, ref) => {
    const {className, image, name, price, link } = props
    return (
        <div className={`ProductCard ${className}`} ref={ref}>
            <Link className="ProductCard-link-image" to={link}>
                <img className="ProductCard-image" src={image} alt={name} />        
            </Link>
            <span className="ProductCard-price">{price}</span>
            <Link className="ProductCard-name" to={link}>
                {name}
            </Link>
        </div>
    )
})

ProductCard.defaultProps = {
    className: "",
    image: "",
    price: "",
    name: "",
    link:"/"
}

ProductCard.propTypes = {
    className: Proptypes.string,
    image: Proptypes.string,
    price: Proptypes.string,
    name: Proptypes.string,
    link: Proptypes.string,
}

export default ProductCard