import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero"
import GalleryItem from "../components/Gallery/GalleryItem";
import galleryItem1 from "../images/Gallery/gallery-item-1.jpg";
import galleryItem2 from "../images/Gallery/gallery-item-2.jpg";
import galleryItem3 from "../images/Gallery/gallery-item-3.jpg";
import galleryItem4 from "../images/Gallery/gallery-item-4.jpg";
import ProductCard from "../components/ProductCard";
import Footer from "../components/Footer"
import { slides } from "../data/home-slide"
import banner from "../images/Banners/banner-1.jpg";
import { novelties } from "../data/novelties-list"
import { exclusive } from "../data/exclusive-list"
import Spotlight from "../components/Spotlight"
import Slider from "react-slick";
import { Helmet } from "react-helmet";

const IndexPage = () => {

	const renderNoveltiesProduct = () => {
		return novelties && novelties.map((item) => {
			return (
				<div className="col-6 Novelties-col">
					<ProductCard
						className="ml-2 mr-2"
						image={item.image}
						price={item.price}
						name={item.name}
					/>
				</div>
			);
		});
	};

	const renderCarouselItems = () => {
		return novelties && novelties.map((item) => {
			return (

				<div>
					<ProductCard
						image={item.image}
						price={item.price}
						name={item.name}
					/>
				</div>
			);
		});
	}

	const renderCarouselItemsExclusive = () => {
		return exclusive && exclusive.map((item) => {
			return (

				<div>
					<ProductCard
						image={item.image}
						price={item.price}
						name={item.name}
					/>
				</div>
			);
		});
	}

	const SampleNextArrow = (props) => {
		const { onClick } = props;
		return (
			<button
				className="Carousel-btn-right"
				onClick={onClick}
			>
				<i className="i-custom-arrow" />
			</button>
		);
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow />,
	};

	return (
		<main>
			<Helmet>
				<link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
			</Helmet>
			<section className="Home">
				<Header />
				<Hero slides={slides} staticImage={banner} />
				<section className="Gallery d-none d-md-block">
					<div className="Gallery-container">
						<div className="Gallery-row">
							<GalleryItem
								image={galleryItem1}
								width={624}
								height={610}
								text="Joieria contemporània i d’autor"
								mr={24}
							/>
							<div className="Gallery-wrapper">
								<div className="Gallery-row mb-4">
									<GalleryItem
										image={galleryItem2}
										width={300}
										height={293}
										text="Modernisme"
										mr={24}
									/>
									<GalleryItem
										image={galleryItem3}
										width={300}
										height={293}
										text="Mobiliari"
									/>
								</div>
								<GalleryItem
									image={galleryItem4}
									width={624}
									height={292}
									text="Els terrats de la Pedrera"
								/>
							</div>
						</div>
					</div>
				</section>
				<section className="Novelties">
					<div className="Novelties-container">
						<h3 className="Novelties-title">Novetats</h3>
						<div className="row d-flex d-lg-none">
							{renderNoveltiesProduct()}
						</div>
						<Slider className="Novelties-slick d-none d-lg-block" {...settings}>
							{renderCarouselItems()}
						</Slider>
					</div>
				</section>
				<Spotlight />
				<section className="Exclusive">
					<div className="Exclusive-container">
						<h3 className="Exclusive-title">Exclusius de La Pedrera</h3>
						<div className="row d-flex d-lg-none">
							{renderNoveltiesProduct()}
						</div>
						<Slider className="Novelties-slick d-none d-lg-block" {...settings}>
							{renderCarouselItemsExclusive()}
						</Slider>
					</div>
				</section>
				<Footer />
			</section>
		</main>
	);
};

export default IndexPage;
