import React from "react";
import Proptypes from 'prop-types'
import Slide from "../Slide";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SvgCurve from "../SvgCurve"


export default function Hero(props) {
	const { slides, staticImage, fullscreen } = props;

	const renderSlideBanner = (fullscreen) => {
		if (!fullscreen) {
			return slides && slides.map((item) => {
				return (
					<Slide>
						<div className="Hero-background" style={{ backgroundImage: `url(${item.image})` }}>
							<div className="Hero-info">
								<h3 className="Hero-title">{item.title}</h3>
								<p className="Hero-text">
									{item.text}
								</p>
								<button className="Hero-btn">
									Veure’n més <i className="i-arrow" />
								</button>
							</div>
						</div>
					</Slide>
				);
			});
		}


		return slides && slides.map((item) => {
			return (
				<Slide>
					<div className="Hero-background" style={{ backgroundImage: `url(${item.image})` }}>
						<div className="Hero-info full">
							<h3 className="Hero-title">{item.title}</h3>
							<p className="Hero-text">
								{item.text}
							</p>
							<button className="Hero-btn">
								Veure’n més <i className="i-arrow" />
							</button>
						</div>
					</div>
				</Slide>
			);
		});

	};

	const renderHero = () => {
		if (!fullscreen) {
			return (
				<div className="Hero-wrapper-row">
					<Carousel className="Hero-carousel" interval={3000} autoPlay infiniteLoop>
						{renderSlideBanner()}
					</Carousel>
					<img className="Hero-img" src={staticImage} alt="banner homepage" />
				</div>
			)
		}

		return (
			<div className="Hero-wrapper-row">
				<Carousel className="Hero-carousel full" interval={3000} autoPlay infiniteLoop>
					{renderSlideBanner(fullscreen)}
				</Carousel>
			</div>
		)
	}

	return (
		<section className="Hero">
			<div className="Hero-mobile-content">
				<img
					className="Hero-mobile-img"
					src={staticImage}
					alt="banner homepage"
				/>
				<div className="Hero-mobile-info">
					<h3 className="Hero-mobile-title">
						Cels rasos, els sostres de la Pedrera
					</h3>
					<p className="Hero-mobile-text">
						Col·lecció de peces simples i sinuoses que imiten les
						volutes que decoren els sostres de la Casa Milà,
						popularment coneguda com La Pedrera
					</p>
					<button className="Hero-mobile-btn">
						Veure’n més <i className="i-arrow" />
					</button>
				</div>
			</div>
			{renderHero()}
			<SvgCurve className="Hero-curve-short" icon="curveSvgHeroMobile" />
			<SvgCurve className="Hero-curve-small" icon="heroCurveSmall" />
			<SvgCurve className="Hero-curve-medium" icon="curveSvgHeroTablet" />
			<SvgCurve className="Hero-curve-large" icon="curveSvgHero" />
			
		</section>
	);
}

Hero.defaultProps = {
	slides: [],
	fullscreen: false,
	staticImage:""
}

Hero.propTypes = {
	slides: Proptypes.array,
	fullscreen: Proptypes.bool,
	staticImage:Proptypes.string
}