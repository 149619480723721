import image1 from "../images/Novelties/product-item-2.jpg"
import image2 from "../images/Novelties/product-item-1.jpg"
import image3 from "../images/Novelties/product-item-3.jpg"
import image4 from "../images/Novelties/product-item-4.jpg"
export const novelties = [
    {
        id:1,
        image:image1,
        price: '20,00 €',
        name:'AMPOLLA PORTA LA PEDRERA PETITA TARONJA'
    },
    {
        id:2,
        image:image2,
        price: '340,00 €',
        name:'Collaret de flors daurat'
    },
    {
        id:3,
        image:image3,
        price: '22,00 €',
        name:'CARTERA MITJA LLUNA PALLA DE PELL VEGANA'
    },
    {
        id:4,
        image:image4,
        price: '40,00 €',
        name:'Gerro inka rosa'
    },
    {
        id:5,
        image:image2,
        price: '340,00 €',
        name:'Collaret de flors daurat'
    },
    {
        id:6,
        image:image1,
        price: '20,00 €',
        name:'AMPOLLA PORTA LA PEDRERA PETITA TARONJA'
    },
    {
        id:7,
        image:image4,
        price: '40,00 €',
        name:'Gerro inka rosa'
    },
    {
        id:8,
        image:image3,
        price: '22,00 €',
        name:'CARTERA MITJA LLUNA PALLA DE PELL VEGANA'
    },
]