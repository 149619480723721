import React from 'react'
import SvgCurve from '../SvgCurve'
import spotlight1 from "../../images/Spotlight/spotlight-image.jpg";

export default function Spotlight() {
    return (
        <section className="Spotlight">
            <SvgCurve className="Spotlight-icon-extra-small" icon="spotlightExtraSmall" />
            <SvgCurve className="Spotlight-icon-small" icon="spotlightSmall" />
            <SvgCurve className="Spotlight-icon-large" icon="spotlightLarge" />
            <div className="Spotlight-container">
                <div className="Spotlight-content">
                    <div className="Spotlight-info">
                        <h3 className="Spotlight-title">
                            Artisan spotlight
                        </h3>
                        <span className="Spotlight-subtitle">
                            Paper Clay
                        </span>
                        <span className="Spotlight-author">
                            Paola Paronetto
                        </span>
                        <p className="Spotlight-text">
                            Aquestes peces es realitzen amb argila de
                            paper. Són peces úniques fetes a mà, per
                            aquest motiu no n’hi ha dues d’iguals. Per
                            nosaltres les diferències són un valor
                            afegit que en demostren la seva
                            exclusivitat.
                        </p>
                        <button className="Spotlight-btn d-none d-lg-flex">
                            Comprar col·lecció <i className="Spotlight-arrow" />
                        </button>
                    </div>
                    <img
                        className="Spotlight-img"
                        src={spotlight1}
                        alt="Spotlight"
                    />
                    <button className="Spotlight-btn d-flex d-lg-none">
                        Comprar col·lecció<i className="Spotlight-arrow" />
                    </button>
                </div>
            </div>
        </section>
    )
}
