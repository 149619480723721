import image1 from "../images/Exclusive/exclusive-1.jpg"
import image2 from "../images/Exclusive/exclusive-2.jpg"
import image3 from "../images/Exclusive/exclusive-3.jpg"
import image4 from "../images/Exclusive/exclusive-4.jpg"
export const exclusive = [
    {
        id:1,
        image:image1,
        price: '56,00 €',
        name:'Reprodució de la portaReprodució de la porta'
    },
    {
        id:2,
        image:image2,
        price: '43,00 €',
        name:'GUERRER DOBLE PETIT'
    },
    {
        id:3,
        image:image3,
        price: '60,00 €',
        name:'POM DE PORTA COR CASA MILÀ “LA PEDRERA”'
    },
    {
        id:4,
        image:image4,
        price: '55,00 €',
        name:'POM DE PORTA PETIT CASA MILÀ “LA PEDRERA”OM DE PORTA PETIT CASA MILÀ “LA PEDRERA”'
    },
    {
        id:5,
        image:image1,
        price: '56,00 €',
        name:'Reprodució de la portaReprodució de la porta'
    },
    {
        id:6,
        image:image2,
        price: '43,00 €',
        name:'GUERRER DOBLE PETIT'
    },
    {
        id:7,
        image:image3,
        price: '60,00 €',
        name:'POM DE PORTA COR CASA MILÀ “LA PEDRERA”'
    },
    {
        id:8,
        image:image4,
        price: '55,00 €',
        name:'POM DE PORTA PETIT CASA MILÀ “LA PEDRERA”OM DE PORTA PETIT CASA MILÀ “LA PEDRERA”'
    },

]