import banner1 from "../images/Banners/hero-home.jpg";

export const slides = [
    {
        image:banner1,
        title:"Cels rasos, els sostres",
        text:"Col·lecció de peces simples i sinuoses que imiten les volutes que decoren els sostres de la Casa Milà, popularment coneguda com La Pedrera"
    },
    {
        image:banner1,
        title:"La Pedrera by Lupo, oda a Gaudí",
        text:"Les bosses de Lupo interpreten els balcons de la Pedrera. Són una oda a la meravellosa arquitectura modernista de Gaudí."
    },
    {
        image:banner1,
        title:"Masriera, joieria modernista d'autor",
        text:"Un símbol de Barcelona, joieria modernista artística on l'ús dels procediments originals fa de cada joia una obra d'art única."
    },
]